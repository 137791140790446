import React from 'react';

import './Certificates.css';
import { useTranslation } from 'react-i18next';

export function Certificates() {
  const { i18n } = useTranslation();

  return <div className="main-content">
    <div className='text-bg'>
      <h3>{i18n.t("certificates.title")}</h3>
      <div className='text'>cokolwiek</div>
    </div>
  </div>;
}
