import React from 'react';

import './About.css';
import { useTranslation } from 'react-i18next';

export function About() {
  const { i18n } = useTranslation();

  return <div className="About main-content">
    <div className='text-bg'>
      <h3>{i18n.t("about.title")}</h3>
      <div className='text'>{i18n.t("about.text")}</div>
    </div>
  </div>;
}
