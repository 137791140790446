import { useState } from 'react';
import './App.css';
import { Logo } from './components/Logo/Logo';
import { useTranslation } from "react-i18next";
import { Outlet, NavLink } from "react-router-dom";

function App() {
  const { i18n } = useTranslation();
  const [ navOpen, setNavOpen ] = useState<boolean>(false);

  const setNavOpenFalse = () => setNavOpen(false);
  const toggleNav = () => setNavOpen(!navOpen);

  return (
    <div className="App">
      <header>
        <Logo />
        <div className='burger mobile' onClick={toggleNav}></div>
        <nav data-open={navOpen} className='main-menu underline'>
          <NavLink to={'/'} onClick={setNavOpenFalse}>{i18n.t("menu.start")}</NavLink>
          <NavLink to={'about'} onClick={setNavOpenFalse}>{i18n.t("menu.about")}</NavLink>
          {/* <NavLink to={'certificates'} onClick={setNavOpenFalse}>{i18n.t("menu.certificates")}</NavLink> */}
          <NavLink to={'contact'} onClick={setNavOpenFalse}>{i18n.t("menu.contact")}</NavLink>
          {/* <NavLink to={'configurator'} onClick={setNavOpenFalse}>{i18n.t("menu.configurator")}</NavLink> */}
          <div className='lang mobile'>
            <span onClick={() => i18n.changeLanguage('pl')}>PL</span>
            <span onClick={() => i18n.changeLanguage('en')}>EN</span>
          </div>
        </nav>
        <div className='lang desktop'>
          <span onClick={() => i18n.changeLanguage('pl')}>PL</span>
          <span onClick={() => i18n.changeLanguage('en')}>EN</span>
        </div>
      </header>
      <main>
        <Outlet />
      </main>
    </div>
  );
}

export default App;
