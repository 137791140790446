import React, { CSSProperties } from 'react';

import './Configurator.css';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom';

import cctv_tilemenu from "../../assets/cctv-tilemenu.webp";
import alarm_tilemenu from "../../assets/alarm-tilemenu.webp";
import smarthome_tilemenu from "../../assets/smarthome-tile.webp";

export function Configurator() {
  const { i18n } = useTranslation()

  const cctv = {"--tile-bg": "url(" + cctv_tilemenu + ")"} as CSSProperties;
  const alarm = {"--tile-bg": "url(" + alarm_tilemenu + ")"} as CSSProperties;
  const smarthome = {"--tile-bg": "url(" + smarthome_tilemenu + ")"} as CSSProperties;

  return <div className="main-content">
      <h3>{i18n.t("configurator.title")}</h3>
      <nav className='tilemenu'>
        <NavLink style={cctv} to={"/cctv"} data-text={i18n.t("configurator.submenu.cctv")}></NavLink>
        <NavLink style={alarm} to={"/alarm"} data-text={i18n.t("configurator.submenu.alarm")}></NavLink>
        <NavLink to={"/videophone"} data-text={i18n.t("configurator.submenu.videophone")}></NavLink>
        <NavLink style={smarthome} to={"/smarthome"} data-text={i18n.t("configurator.submenu.smarthome")}></NavLink>
      </nav>
  </div>;
}
