
import { CSSProperties } from 'react';
import './Logo.css';

export function Logo() {
  return <div className="logo">
    <div className="square top-right"></div>
    <div className="square bottom-left"></div>
    <div className="square bottom-right"></div>
    <div className="logo-text">TSN</div>
  </div >;
}
