
import React from 'react';

import './Contact.css';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Icon, LatLngTuple } from 'leaflet';
import "leaflet/dist/leaflet.css";
import markerIcon from '../../assets/marker-icon.png'

export function Contact() {
  const { i18n } = useTranslation();
  const position: LatLngTuple = [54.31195, 18.59721]
  const icon = new Icon({
    iconUrl: markerIcon,
    iconSize: [25, 41], // size of the icon
    iconAnchor: [12, 40], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -35] // point from which the popup should open relative to the iconAnchor
  })

  return <div className="main-content Contact">
    <div className='text-bg'>
      <div className='container-1'>
        <div className='container-2'>
          <h3>{i18n.t("contact.title")}</h3>
          <div className='text'>Niepołomicka, 80-180 Gdańsk, Polska</div>
          <div className='text'>biuro@tsn24.pl</div>
          <div className='text'>796 911 986</div>
          <form onSubmit={e => {
            e.preventDefault();

            const target = e.target as any
            const name = target.name.value;
            const phone = target.phone.value;
            const email = target.email.value;
            const address = target.address.value;
            const subject = target.subject.value;
            const content = target.content.value;
            
            const body = `Imię: ${name}
            E-mail: ${email}
            Telefon: ${phone}
            Adres: ${address}
            Treść:
            ${content}`;
            
            const href = "mailto:" + "biuro@tsn24.pl"
            + "?subject=" + encodeURIComponent(subject)
            + "&body=" + encodeURIComponent(body);
            
            const a = document.createElement("a");
            a.href = href;
            a.click();
          }}>
            <label>
              <input name="name" type="text" required />
              <span>{i18n.t("contact.form.name")}</span>
            </label>
            <label>
              <input name="email" type="email" required />
              <span>E-mail</span>
            </label>
            <label>
              <input name="phone" type="tel" />
              <span>{i18n.t("contact.form.phone")}</span>
            </label>
            <label>
              <input name="address" type="text" />
              <span>{i18n.t("contact.form.address")}</span>
            </label>

            <label className='span-2'>
              <input name="subject" type="text" required />
              <span>{i18n.t("contact.form.subject")}</span>
            </label>
            <label className='span-2'>
              <textarea name="content" required style={{ height: "10em" }} maxLength={3000} />
              <span>{i18n.t("contact.form.content")}</span>
            </label>
            <button className='span-2 glow' role='submit'>Wyślij</button>
          </form>
        </div>
        <MapContainer center={position} zoom={17} scrollWheelZoom={false}
          dragging={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} icon={icon}>
            {/* <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup> */}
          </Marker>
        </MapContainer>
      </div>
    </div>
  </div>;
}
