import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from "react-i18next";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from './App';
import { About } from './components/About/About';
import { Certificates } from './components/Certificates/Certificates';
import { Contact } from './components/Contact/Contact';
import { Start } from './components/Start/Start';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Configurator } from './components/Configurator/Configurator';
import { Cctv, OPTIONS } from './components/Configurator/components/Cctv/Cctv';

i18next
  .use(initReactI18next)
  .init({
    lng: 'pl',
    debug: true,
    resources: {
      pl: {
        translation: {
          menu: {
            start: "Start",
            about: "O nas",
            certificates: "Certyfikaty i uprawnienia",
            contact: "Kontakt",
            configurator: "Konfigurator"
          },
          start: {
            slides: {
              lte_router: {
                title: "Routery kierunkowe lte",
                description: "Internet mobilny w lokalizacjach o ograniczonych możliwościach transferu danych"
              },
              structural_cabling: {
                title: "Okablowanie strukturalne",
                description: "Fizyczna warstwa każdej współczesnej sieci internetowej. Zarówno klasyczna miedziana oraz na bazie światłowodu"
              },
              mesh_networks: {
                title: "Sieci mesh, usługi sieciowe",
                description: "Rozległe sieci wi-fi, zarządzanie siecią, VLANy oraz routing"
              },
              fire_signaling: {
                title: "System sygnalizacji pożaru",
                description: "Projekt oraz wykonawstwo"
              },
              production_service: {
                title: "Kompleksowa obsługa obiektów produkcyjnych oraz magazynów wysokiego składowania",
                description: "Rejestracja czasu pracy, system kontroli dostępu, sygnalizacja włamania i napadu, kamery IP"
              },
              construction_security: {
                title: "Zabezpieczenie placów budowy",
                description: "Zintegrowane połączenie kamer z wbudowaną analityką AI oraz klasycznego systemu sygnalizacji włamania wspomagane przez agencję ochrony fizycznej."
              },
              building_automation: {
                title: "Automatyka budynkowa oraz integracje",
                description: "Zarządzanie fizycznymi elementami wykonawczymi obiektu: kontrola dostępu, przewietrzanie, kontrola zużycia energii elektrycznej i cieplnej"
              },
              cctv: {
                title: "Rozwiązania CCTV",
                description: "Systemy IP oraz hybrydowe"
              },
              smoke_detection: {
                title: "System oddymiania grawitacyjnego",
                description: "Projekt oraz wykonawstwo"
              },
              projects: {
                title: "Projekty elektryczne oraz elektrotechniczne",
                description: ""
              },
            }
          },
          about: {
            title: "TSN sp. z o.o.",
            text: "Jesteśmy konsorcjum elektrotechnicznym działającym od 2012. Nasi technicy i inżynierowie to specjaliści w dziedzinie światłowodowej, elektrycznej, sieci strukturalnych, systemów p-poż oraz współczesnych CCTV. Specjalizujemy się w obsłudze klientów indywidualnych klasy premium, obiektów magazynowych, komercyjnych, placówek oświatowych oraz wszelkich obiektów objętych obowiązkiem wyposażenia w system sygnalizacji pożaru. Posiadamy własny dział techniczny oraz dział projektowy. Bazujemy na rozwiązanich takich producentów jak: Grenton, Fibaro, Cisco, Genevo, Mikrotik, Ubiquitti, Satel, Risco, Dahua, Hik-Vision."
          },
          certificates: {
            title: "Certyfikaty i uprawnienia"
          },
          contact: {
            title: "Kontakt",
            form: {
              name: "Imię",
              phone: "Numer telefonu",
              address: "Adres",
              subject: "Temat",
              content: "Wiadomość"
            }
          },
          configurator: {
            title: "Wybierz rodzaj instalacji",
            submenu: {
              cctv: "CCTV",
              alarm: "System sygnalizacji włamania i napadu",
              videophone: "Wideofon IP",
              smarthome: "Smarthome"
            }
          }
        }
      },
      en: {
        translation: {
          menu: {
            start: "Start",
            about: "About us",
            certificates: "Certificates and licenses",
            contact: "Contact",
            configurator: "Configurator"
          },
          start: {
            slides: {
              lte_router: {
                title: "Directional LTE Routers",
                description: "Mobile Internet in locations with limited data transfer capabilities"
              },
              structural_cabling: {
                title: "Structural cabling",
                description: "The physical layer of every modern Internet network. Both classic copper and fiber-based"
              },
              mesh_networks: {
                title: "Mesh networks and network services",
                description: "Wide-area Wi-Fi networks, network management, VLANs and routing"
              },
              fire_signaling: {
                title: "Fire alarm systems",
                description: "Design and execution"
              },
              production_service: {
                title: "Comprehensive service for production facilities and high bay warehouses",
                description: "Working time registration, access control system, burglary and attack detection, IP cameras"
              },
              construction_security: {
                title: "Construction site security",
                description: "Integration of cameras with built-in AI analytics and a classic intrusion detection system supported by a physical security agency."
              },
              building_automation: {
                title: "Building automation and integration",
                description: "Management of physical executive elements of the facility: access control, ventilation, control of electricity and heat consumption"
              },
              cctv: {
                title: "CCTV solutions",
                description: "IP and hybrid systems"
              },
              smoke_detection: {
                title: "Gravity smoke extraction system",
                description: "Design and execution"
              },
              projects: {
                title: "Electrical and electrotechnical projects",
                description: ""
              },
            }
          },
          about: {
            title: "TSN sp. z o.o.",
            text: "We are an electrical engineering consortium operating since 2012. Our technicians and engineers are specialists in the field of fiber optics, electricity, structural networks, fire protection systems and modern CCTV. We specialize in serving premium individual clients, warehouse and commercial facilities, educational institutions and all facilities required to be equipped with a fire alarm system. We have our own technical department and design department. We rely on solutions from manufacturers such as: Grenton, Fibaro, Cisco, Genevo, Mikrotik, Ubiquitti, Satel, Risco, Dahua, Hik-Vision."
          },
          certificates: {
            title: "Certificates and licenses"
          },
          contact: {
            title: "Contact",
            form: {
              name: "First name",
              phone: "Phone number",
              address: "Address",
              subject: "Subject",
              content: "Message"
            }
          },
          configurator: {
            title: "Choose instalation type",
            submenu: {
              cctv: "CCTV",
              alarm: "Burglary and attack detection system",
              videophone: "IP Videophone",
              smarthome: "Smarthome"
            }
          }
        }
      }
    }
  });

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Start />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "certificates",
        element: <Certificates />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "configurator",
        element: <Configurator />,
      },
      {
        path: "cctv",
        element: <Cctv />,
        loader: () => Promise.resolve(OPTIONS)
      },
      {
        path: "alarm",
        element: <div>Alarm</div>,
      },
      {
        path: "videophone",
        element: <div>Wideofony IP</div>,
      },
      {
        path: "smarthome",
        element: <div>Smart home</div>,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
